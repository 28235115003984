<template>
  <footer>
      <!-- footer content begin -->
      <div class="uk-section">
        <div class="uk-container uk-margin-top">
          <div class="uk-grid">
            <div class="uk-width-2-3@m">
              <div
                class="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid"
                data-uk-grid=""
              >
                <div class="uk-first-column">
                  <h5>Site</h5>
                  <ul class="uk-list uk-link-text">
                    <li>
                      <router-link :to="{ name: 'public.privacy-policy' }"
                        >Privacy Policy
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'public.dcma' }"
                        >Do Not Sell My Info</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'public.terms-of-use' }"
                        >Terms of Use</router-link
                      >
                    </li>
                  </ul>
                </div>
                <div>
                  <h5>About</h5>
                  <ul class="uk-list uk-link-text">
                    <li>
                      <router-link
                        :to="{ name: 'public.about-us' }"
                        class="text-base"
                        >Overview
                      </router-link>
                    </li>
                    <li>
                      <a href="https://myforexglobal.com/blog" class="text-base"
                        >Blog</a
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'public.contact-us' }"
                        class="text-base"
                        >Contact Us</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'affiliate' }" class="text-base"
                        >Affiliate Program</router-link
                      >
                    </li>
                  </ul>
                </div>
                <div class="in-margin-top-60@s">
                  <h5>Learning</h5>
                  <ul class="uk-list uk-link-text">
                    <li>
                      <router-link :to="{ name: 'public.academy' }"
                        >First course</router-link
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCtLrduu1x1kuwbD9S16PZmg/featured"
                        target="_blank"
                      >
                        Free Learning Videos
                      </a>
                    </li>
                    <li>
                      <a href="https://tinyurl.com/yckkpwh6" target="_blank"
                        >Charting Software</a
                      >
                    </li>
                  </ul>
                </div>
              </div>

              <partners />

              <div class="uk-margin-medium-top">
                <disclaimer :disclaimer="disclaimer" :loading="loading" />
              </div>
            </div>
            <div class="uk-width-1-3@m uk-flex uk-flex-right@m">
              <ul class="uk-list uk-link-text in-footer-socials">
                <li>
                  <h5>Join us on social</h5>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/myforexglobal/"
                    target="_blank"
                    class="fb-bg"
                    ><i style="margin-right: 10px" class="fa fa-facebook"></i
                    >Facebook</a
                  >
                </li>
                <li>
                  <a
                    href="https://twitter.com/myforexglobal1"
                    target="_blank"
                    class="tw-bg"
                    ><i style="margin-right: 10px" class="fa fa-twitter"></i
                    >Twitter</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCtLrduu1x1kuwbD9S16PZmg/featured"
                    target="_blank"
                    class="yt-bg"
                    ><i style="margin-right: 10px" class="fa fa-youtube"></i
                    >Youtube</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/myforexglobal/"
                    target="_blank"
                    class="ig-bg"
                    ><i style="margin-right: 10px" class="fa fa-instagram"></i
                    >Instagram</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@myforexglobal?"
                    target="_blank"
                    class="tl-bg"
                    ><i style="margin-right: 10px" class="fa fa-telegram"></i
                    >TikTok</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="uk-margin-large" />
        <div class="uk-container">
          <div class="uk-grid uk-flex uk-flex-middle">
            <div class="uk-width-2-3@m uk-text-small">
              <p class="copyright-text">
                ©2024 MyForexGlobal. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- footer content end -->
    </footer>
</template>

<script>
import Disclaimer from '../../shared/Disclaimer'

export default {
  components: {
    Disclaimer
  },

  props: {
    disclaimer: {
      default() {
        return ''
      }
    },

    loading: {
      default() {
        return true
      }
    }
  }
}
</script>