<template>
  <div>
    <app-header />

    <main v-if="!loading">
      <!-- slideshow content begin -->
      <div class="uk-section uk-padding-remove-vertical in-slideshow-gradient">
        <div
          id="particles-js"
          class="uk-light in-slideshow uk-background-contain"
          data-src="/theme/decor1.svg"
          data-uk-img
          data-uk-slideshow
        >
          <hr />
          <ul class="uk-slideshow-items">
            <li class="uk-flex uk-flex-middle">
              <div class="uk-container">
                <div class="uk-grid-large uk-flex-middle" data-uk-grid>
                  <div class="uk-width-1-2@s in-slide-text">
                    <h1 class="uk-heading-small">
                      <textarea
                        rows="2"
                        v-model="slider.title"
                        style="font-size: 40px"
                        class="uk-textarea"
                      ></textarea>
                    </h1>
                    <div class="uk-margin">
                      <label>Change Image</label>
                      <input type="file" ref="slider_image" class="uk-input" />
                    </div>
                    <editor v-model="slider.tagline" height="300" />
                    <div class="uk-margin-top">
                      <a
                        href="#"
                        @click.prevent="updateSlider"
                        class="uk-button uk-button-primary"
                        >Save</a
                      >
                    </div>
                  </div>
                  <div class="in-slide-img uk-visible@m">
                    <img
                      :src="slider.background"
                      alt="image-slide"
                      width="652"
                      height="746"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- slideshow content end -->

      <!-- section content begin -->
      <div
        class="
          uk-section
          in-equity-11
          uk-background-contain uk-background-top-right
        "
        :data-src="how.background"
        data-uk-img
      >
        <div class="uk-container uk-margin-top uk-margin-bottom">
          <div class="uk-width-3-4@m">
            <div
              class="
                uk-grid-medium
                uk-grid-match
                uk-child-width-1-3@m
                uk-child-width-1-2@s
              "
              data-uk-grid
            >
              <div class="uk-width-1-1">
                <h1 class="uk-margin-small-bottom">
                  <textarea
                    rows="1"
                    v-model="how.title"
                    style="font-size: 40px"
                    class="uk-textarea"
                  ></textarea>
                </h1>
                <p class="uk-text-lead uk-margin-remove-top text-dark">
                  <textarea
                    rows="3"
                    v-model="how.tagline"
                    class="uk-textarea"
                  ></textarea>
                </p>
                <div class="uk-margin">
                  <label>Change Background</label>
                  <input type="file" ref="how_background" class="uk-input" />
                </div>
                <div class="uk-width-1-1">
                  <p class="uk-text-small uk-text-dark">
                    <span
                      class="
                        uk-label uk-label-info uk-margin-small-right
                        in-label-small
                      "
                      >Note</span
                    >
                    <textarea
                      rows="4"
                      v-model="how.note"
                      class="uk-textarea"
                    ></textarea>
                  </p>
                </div>
              </div>
              <div v-for="(item, i) in how.features" :key="`how-item-${i}`">
                <div
                  class="
                    uk-card
                    uk-card-default
                    uk-card-body
                    uk-border-rounded
                    uk-box-shadow-medium
                  "
                >
                  <div class="uk-flex uk-flex-middle">
                    <div class="uk-margin-right">
                      <a
                        href="#"
                        style="font-size: 12px"
                        @click.prevent="openImageSelector(i)"
                        >Change</a
                      >
                      <input
                        type="file"
                        :id="`slider_featured_item_${i}`"
                        ref="slider_featured"
                        @change="changeFeaturedIcon(i)"
                        style="display: none"
                      />
                      <template v-if="!changing_icon">
                        <img
                          :src="item.icon"
                          alt="icon-1"
                          width="96"
                          v-if="changed_icons[i] == false"
                          style="width: 128px"
                        />
                        <image-preview
                          v-if="changed_icons[i] != false"
                          :image="changed_icons[i]"
                          style="width: 128px"
                        />
                      </template>
                    </div>
                    <div>
                      <h5 class="uk-margin-small-bottom">
                        <input
                          type="text"
                          v-model="item.title"
                          class="uk-input"
                        />
                      </h5>
                      <p class="uk-text-small uk-margin-remove">
                        <textarea
                          rows="3"
                          class="uk-textarea"
                          v-model="item.content"
                        ></textarea>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-margin-top">
              <a
                href="#"
                class="uk-button uk-button-primary"
                @click.prevent="updateHow"
                >Save</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- section content end -->

      <!-- section content begin -->
      <div class="uk-section" style="background: #fff">
        <div class="uk-container">
          <div class="uk-grid uk-flex uk-flex-center">
            <div class="uk-width-1-2@m uk-text-center">
              <h2 class="uk-text-muted uk-margin-remove-bottom uk-text-center">
                Frequently Asked Questions
              </h2>
            </div>
            <div class="uk-width-1-1@m">
              <div
                class="uk-grid uk-child-width-1-2@m uk-margin-medium-top"
                data-uk-grid=""
              >
                <div
                  class="uk-first-column"
                  v-for="(item, i) in faq"
                  :key="`faq-${i}`"
                >
                  <ul class="in-faq-2 uk-accordion">
                    <li class="uk-box-shadow-medium uk-open">
                      <div class="uk-accordion-title" aria-expanded="true">
                        <div
                          class="
                            uk-card
                            uk-card-default
                            uk-card-body
                            uk-border-rounded
                          "
                        >
                          <a
                            href="#"
                            class="
                              uk-float-right uk-margin-right uk-text-danger
                            "
                            @click.prevent="faq.splice(i, 1)"
                            >Delete</a
                          >
                          <i class="fas fa-question fa-xs"></i>
                          <input
                            type="text"
                            v-model="item.title"
                            class="uk-input"
                          />
                        </div>
                      </div>
                      <div
                        class="
                          uk-accordion-content
                          uk-card
                          uk-card-default
                          uk-card-body
                          uk-border-rounded
                        "
                      >
                        <editor v-model="item.content" height="300" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="uk-margin">
                <a href="#" class="uk-float-right" @click.prevent="faq.push({})"
                  >Add</a
                >
                <a
                  href="#"
                  class="uk-button uk-button-primary"
                  @click.prevent="updateFaq"
                  >Save</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- section content end -->
      
      <div
        class="
          uk-section uk-section-primary uk-preserve-color
          in-equity-6
          uk-background-contain uk-background-center
        "
        style="background-image: url('/new/in-decor-2.svg')"
      >
        <getting-started
          :loading="loading"
          :getting_started="getting_started"
        />
      </div>
    </main>

    <loading v-if="loading" />

    <app-footer :loading="loading" :disclaimer="disclaimer" />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import GettingStarted from "../shared/GettingStarted";

export default {
  components: {
    AppHeader,
    AppFooter,
    GettingStarted
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      slider: {},
      loading_slider: false,
      how: {},
      loading_how: false,
      changed_icons: [false, false, false],
      changing_icon: false,
      faq: [],
      loading_faq: false,
      getting_started: {}
    };
  },

  methods: {
    changeFeaturedIcon(i) {
      this.changed_icons[i] = this.$refs.slider_featured[i].files[0];
      this.changing_icon = true;

      setTimeout(() => {
        this.changing_icon = false;
      }, 10);
    },

    openImageSelector(i) {
      document.getElementById(`slider_featured_item_${i}`).click();
    },

    updateHow() {
      this.loading_how = true

      let data = {
        title: this.how.title,
        tagline: this.how.tagline,
        note: this.how.note,
      }

      if (this.$refs.how_background.files[0]) {
        data.append("background", this.$refs.how_background.files[0]);
      }

      this.how.features.forEach((item, i) => {
        data.append(`features[${i}][title]`, item.title);
        data.append(`features[${i}][content]`, item.content);

        if (this.changed_icons[i]) {
          data.append(`features[${i}][icon]`, this.changed_icons[i]);
        }
      });

      this.$axios
        .post("/api/v1/admin/sitecontent/public-academy-how", data)
        .then((response) => {
          this.$toasted.success("Updated");
          this.how = response.data.how;
          this.loading_how = false;
        });
    },

    updateFaq() {
      this.loading_faq = true

      this.$axios
        .post("/api/v1/admin/sitecontent/faq", { faq: this.faq })
        .then((response) => {
          this.$toasted.success("Updated");
          this.faq = response.data.faq;
          this.loading_faq = false;
        });
    },

    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/sitecontent/public-academy").then((response) => {
        this.slider = response.data.slider;
        this.how = response.data.how;
        this.faq = response.data.faq;
        this.getting_started = response.data.getting_started;
        this.disclaimer = response.data.disclaimer;
        this.loading = false;
      });
    },

    updateSlider() {
      this.loading_slider = true;

      let data = {
        title: this.slider.title,
        tagline: this.slider.tagline,
      };

      if (this.$refs.slider_background.files[0]) {
        data.append("background", this.$refs.slider_background.files[0]);
      }

      this.$axios
        .post("/api/v1/admin/sitecontent/public-academy-slider", data)
        .then((response) => {
          this.$toasted.success("Updated");
          this.slider = response.data.slider;
          this.loading_slider = false;
        });
    },
  },
};
</script>
