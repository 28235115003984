<template>
  <div>
    <template v-if="!updating">
      <h5 class="uk-margin-remove">Disclaimer</h5>
      <editor v-model="disclaimer" height="300px" />
      <div style="margin-top: 1rem">
        <a
          href="#"
          class="uk-button uk-button-secondary"
          @click.prevent="submit"
          >Save</a
        >
      </div>
    </template>
    <loading v-if="updating" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      disc: this.disclaimer,
      updating: this.loading
    }
  },

  props: ['disclaimer', 'loading'],

  methods: {
    submit() {
      this.updating = true

      this.$axios
        .post("/api/v1/admin/sitecontent/footer-disclaimer", { disclaimer: this.disc })
        .then((response) => {
          this.$toasted.success("Updated");
          this.disclaimer = response.data.disclaimer;
          this.updating = false
        });
    }
  },

  watch: {
    loading(newval) {
      this.updating = newval
    }
  }
};
</script>
