<template>
  <div>
    <div
      class="uk-container uk-margin-small-bottom"
      v-if="! updating"
    >
      <div class="uk-grid uk-flex uk-flex-center">
        <div class="uk-width-3-4@m uk-margin-top">
          <div
            class="
              uk-grid uk-child-width-1-3@s uk-child-width-1-3@m uk-text-center
              in-register
            "
          >
            <div class="uk-width-1-1 in-h-line uk-first-column">
              <textarea
                class="uk-textarea uk-text-center"
                placeholder="Title"
                rows="1"
                v-model="getting_started.title"
                style="font-size: 30px"
              ></textarea>
            </div>
            <div
              class="uk-grid-margin uk-first-column"
              v-for="(item, i) in getting_started.items"
              :key="`getting-starteditem-${i}`"
            >
              <span class="in-icon-wrap circle">{{ i + 1 }}</span>
              <p>
                <textarea v-model="getting_started.items[i]" placeholder="Content" rows="3" class="uk-textarea"></textarea>
              </p>
            </div>
            <div class="uk-width-1-1 uk-grid-margin uk-first-column">
              <a
                href="#"
                class="uk-button uk-button-secondary"
                @click.prevent="submit"
                >Save</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="updating" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      updating: this.loading,
      started: this.getting_started
    }
  },

  props: ['getting_started', 'loading'],

  methods: {
    submit() {
      this.updating = true;

      this.$axios
        .post(
          "/api/v1/admin/sitecontent/public-home-getting-started",
          this.getting_started
        )
        .then((response) => {
          this.$toasted.success("Updated");
          this.getting_started = response.data.getting_started;
          this.updating = false;
        });
    },
  },

  watch: {
    loading(newval) {
      this.updating = newval
    }
  }
};
</script>
